html {
  overflow-y: hidden;
}

body {
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // flex-grow: 1;
  // min-width: 0;
  // align-items: stretch;

  // -ms-overflow-style: none; /* for Internet Explorer, Edge */
  // scrollbar-width: none; /* for Firefox */
  // &::-webkit-scrollbar {
  //   display: none; /* for Chrome, Safari, and Opera */
  // }
}

.bigo-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.App {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  // // height: 100vh;
  // overflow-y: hidden;
  // overflow-x: hidden;
  // scroll-snap-type: y mandatory;
  // margin-top: -70px;

  // // width: 100vw;
  // height: 100vh;
  // overflow-y: scroll;
  // scroll-behavior: smooth;
  // scroll-snap-type: mandatory;
  // scroll-snap-type: y mandatory;
  // // display: flex;
  // flex-direction: column;
  // flex-grow: 1;
  // flex-shrink: 0;
  // background-repeat: no-repeat;

  background-position: top;

  > div {
    scroll-snap-align: start;
    // height: 100vh;
    height: auto;
    margin-bottom: 50px;

    &.reveal {
      position: relative;
      // transform: translateY(150px);
      opacity: 0;
      transition: 0.5s all ease;
    }
    &.reveal.active {
      // transform: translateY(0);
      opacity: 1;
    }

    > .row {
      height: 100%;
      align-items: center;
      height: 100vh;
    }
  }
}

input#agree,
input#BusinessagreeCheck {
  border: 1px solid rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: black;
  }

  &:checked {
    background-color: black;
  }
}

label#agree,
label#Businessagree {
  a {
    color: black;
  }

  a:hover {
    color: #00ffe8;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus,
select:focus,
.form-select:focus {
  outline: none !important;
  border-color: #00ffe8;
  box-shadow: none !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.global-presence {
  .watchlive {
    margin-left: -10em;
  }

  .exploremore {
    margin-top: 50px;
    float: right;
  }
}

.join-us {
  margin-bottom: 100px;

  input,
  select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid;
    font-size: 20px;
    border-radius: 0px !important;
  }

  input {
    &:hover,
    &:focus {
      border-bottom: 2px solid #00ffe8;
      background-color: transparent;
      box-shadow: unset;
    }
  }

  .select-items {
    background-color: white;

    &:hover {
      background-color: #00ffe8;
    }
  }

  .join-us-columns {
    position: relative;
  }

  .plr-20p {
    padding-left: 20%;
    padding-right: 20%;
  }

  .ta-left {
    text-align: left;
  }

  .header {
    font-size: 56px;
    text-align: center;
  }

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .apply {
    margin-top: 50px;
  }

  .content {
    font-size: 18px;
    font-weight: bold;
    font-family: "SFUIText";
    text-align: left;
    margin-top: 1em;
    width: 600px;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  .fire-content {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "BIGOLIVESANS";
    color: white;
  }

  .socmed-links {
    .ic {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }

    a {
      margin-left: 5px;
      // margin-right: 10px;
    }

    button {
      height: 44px;
      width: 130px;
      font-size: 18px;
      color: white;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 10px;

      &:hover {
        background-color: black;
      }
    }

    span {
      font-family: "BIGOLIVESANS";
      font-size: 24px;
      margin-right: 10px;
    }

    .telegram {
      background-color: #2ca5e0;
      border: 1px solid #2ca5e0;
    }

    .telegram:hover {
      background-color: #000;
      border: 1px solid #000;
    }

    .facebook {
      background-color: #1877f2;
      border: 1px solid #1877f2;
    }

    .facebook:hover {
      background-color: #000;
      border: 1px solid #000;
    }

    .viber {
      background-color: #6140bf;
      border: 1px solid #6140bf;
    }
    .viber:hover {
      background-color: #000;
      border: 1px solid #000;
    }
  }

  .artist {
    // height: 1005px;
    width: 100%;
  }
}

.business-opportunity {
  margin-bottom: 100px;

  input,
  select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid;
    font-size: 20px;
    border-radius: 0px !important;
  }

  input {
    &:hover,
    &:focus {
      border-bottom: 1px solid #00ffe8;
      background-color: transparent;
      box-shadow: unset;
    }
  }

  .select-items {
    background-color: white;

    &:hover {
      background-color: #00ffe8;
    }
  }

  .header {
    font-size: 56px;
  }

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content {
    font-weight: bold;
  }

  .right.content {
    font-size: 18px;
    font-family: "SFUIText";
    text-align: right;
    margin-top: 1em;
    margin-top: 10px;
    width: 500px;
    margin-left: -75px;
    margin-bottom: 65px;
  }

  .left.content {
    font-size: 18px;
    font-family: "SFUIText";
    text-align: center;
    margin-top: 1em;
    margin-top: 10px;
  }

  .ta-right {
    text-align: right;
  }

  .plr-20p {
    padding-right: 20%;
    padding-left: 20%;
  }

  .business-image {
    height: 100%;
    // width: 100%;
  }

  .apply {
    margin-top: 50px;
  }

  label#agree,
  label#Businessagree {
    text-align: left;
  }
}

.contact-us {
  scroll-snap-align: start;

  &.active {
    margin-bottom: 0px;
  }

  > .row {
    height: 100vh;
    align-items: center;
  }

  .socmed-links {
    .business-name {
      float: left;
    }
  }
}

.navbar-nav {
  #close {
    display: none;
  }
}

.show-mobile {
  display: none;
}

.fire-container {
  mask-size: 80%;
  height: 1000px;
  scroll-snap-type: x mandatory;
  -webkit-mask-position-y: 65%;
  // overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;

  .img-content {
    height: 1000px;
    width: 100%;
    background-size: 80%;
    background-position-x: 60%;
    background-position-y: 81%;
    scroll-snap-align: center;
    display: inline-block;
  }
}

.fire-line-container {
  position: relative;
}

.fire-line {
  background-size: 80%;
  background-position-x: 120px;
  background-position-y: 70%;
  height: 1000px;
}

// .nav-link.active {
//   background-image: url("/public/assets/ic_selected.png");
// }

.collapsed .navbar-toggler-icon {
  background-image: url("/public/assets/menu.png") !important;
}

.navbar-toggler-icon {
  background-image: url("/public/assets/close.png") !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-note {
  text-align: left;
  font-size: 10px;
}

.pad-9m {
  padding-top: unset;
}

//Custom font color for element due to white photo
#Prime_Cess{
  color: #9C40FF;
}

@media (max-width: 1600px) {
  .join-us {
    .right.content {
      width: unset;
    }
    .fire-content {
      bottom: 17%;
      left: 52%;
    }
  }

  .business-opportunity {
    .business-image-container .b1,
    .business-image-container .b4,
    .business-image-container .b2,
    .business-image-container .b3 {
      height: 65%;
      margin-top: 0px;
      width: 65%;
    }
  }

  .fire-container {
    .img-content {
      .context {
        width: auto !important;
        /* margin-right: 17% !important; */
        position: absolute;
        bottom: 23%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .emie-bg {
      background-position-y: 13em;
      background-position-x: -18em;
    }
    .emps-bg {
      background-position-y: 6em !important;
      background-position-x: -14em !important;
    }
    .control-dots {
      bottom: 13% !important;
      .dot {
        width: 32px !important;
        height: 32px !important;
        border-radius: 50% !important;
        // background-color: #9C40FF !important;
        background-color: transparent !important;
        opacity: 1 !important;
        box-shadow: none !important;
      }
      .dot::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #9c40ff;
      }
      .dot.selected {
        opacity: 1 !important;
        background-color: transparent !important;
        border: 10px solid #9c40ff !important;
        border-radius: 50% !important;
        width: 32px !important;
        height: 32px !important;
      }
      .dot.selected::before {
        background-color: transparent !important;
      }
    }
  }
}

@media (min-width: 1317px) and (max-width: 1512px) {
  .join-us {
    .socmed-links span {
      font-size: 20px;
    }

    .socmed-links a {
      margin-left: 5px;
    }

    .socmed-links button {
      width: 130px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1316px) {
  .join-us {
    .socmed-links span {
      font-size: 16px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .join-us {
    .fire-container {
      height: 500px;
      .emps-bg {
        background-position-y: 12em !important;
        background-position-x: -20em !important;
      }

      .emie-bg {
        background-position-x: -25em !important;
        background-position-y: 20em !important;
      }

      .img-content {
        height: 500px;
        width: 100%;
        background-size: 75%;
        background-position-x: center;
        background-position-y: 95%;

        .context {
          width: auto !important;
          // margin-right: 34% !important;
          margin: 0 !important;
          bottom: 15%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          h3{
            font-size: 20px;
          }
        }
      }
    }

    .fire-content {
      bottom: 20% !important;
      left: 52%;
      font-size: 13px;
    }

    .fire-line{
      height: 500px;
    }

    .control-dots {
      bottom: 2% !important;
    }
  }
}

@media (max-width: 1199px) {
  .show-mobile {
    display: block;
  }

  .navbar-nav {
    .nav-link.active {
      background-image: unset;
    }
  }

  #basic-navbar-nav a.nav-link {
    background-image: unset;
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
  }

  .navbar-toggler-icon-close {
    display: block;
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: #00ffe8;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
    align-self: end;
    margin-right: 20px;
    margin-top: 20px;

    &:before,
    &:after {
      position: absolute;
      top: 15%;
      left: calc(50% - 0.0625em);
      width: 0.125em;
      height: 70%;
      border-radius: 0.125em;
      transform: rotate(45deg);
      background: currentcolor;
      content: "";
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .navbar-toggler {
    z-index: 10;
  }

  .navbar {
    .navbar-toggler {
      position: absolute;
      right: 20px;
      border: unset;
      top: 15px;
    }
  }

  .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0%;
    background-color: white;
    width: 100%;
    border: 2px solid;
    justify-content: center;
    z-index: 9;
  }

  .business-image-container .b1,
  .business-image-container .b4,
  .business-image-container .b2,
  .business-image-container .b3 {
    height: 50%;
    margin-top: 80px;
  }
}

@media (max-width: 1080px) {
  .bigo-container .navbar {
    padding-left: 50px;
  }
}

@media (max-width: 1023px) {
  .bigo-container {
    scroll-snap-type: unset;
  }

  .App > div {
    display: flex;
    align-items: center;

    &.reveal {
      position: relative;
      transform: unset;
      opacity: 1;
      transition: 0.5s all ease;
    }
    &.reveal.active {
      transform: unset;
      opacity: 1;
    }
  }
  .about-us {
    justify-content: center;

    .row {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
    .dino {
      display: none;
    }
  }

  .global-presence {
    .row {
      flex-direction: column;

      div {
        width: 100%;
      }

      .order-2-mobile {
        order: 2;
      }

      .order-1-mobile {
        order: 1;
      }
    }

    .mobile .stream {
      margin-left: auto;
      margin-right: auto;
    }

    .header {
      margin-top: 5rem;
    }
  }

  .global-event {
    flex-direction: column;
    padding-bottom: 50px;

    .jAMlvv {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      .iKLtWK {
        width: 190px;
      }
    }

    & > .row:nth-child(1) {
      & > :nth-child(1),
      :nth-child(2) {
        width: 100%;
      }
    }
  }

  .join-us {
    margin-bottom: 50px;

    .row {
      flex-direction: column;
      div {
        width: 100%;
      }
    }

    .artist {
      display: none;
    }
  }

  .business-opportunity {
    flex-direction: column;
    padding-bottom: 100px;
    margin-bottom: unset;

    .row {
      text-align: left;

      div {
        width: 100%;
      }
    }
    .business-image {
      display: none;
    }
  }

  .ta-left-mobile {
    text-align: left;
  }

  #basic-navbar-nav a.nav-link {
    margin-left: 50px;
  }

  .fire-container {
    mask-size: 100%;
    height: 1000px;

    .img-content {
      height: 1000px;
      background-size: 90%;

      .context {
        margin-top: 95%;
      }
    }
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 100%;
    background-position-x: 0;
    background-position-y: 50%;
    height: 1000px;
  }

  .contact-us {
    .footer-link {
      li {
        a {
          font-size: 11px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .App > div {
    display: flex;
    align-items: center;

    &.join-us {
      height: unset;
    }
    &.business-opportunity {
      height: unset;
    }
    &.contact-us {
      height: unset;
    }
    &.reveal {
      position: relative;
      transform: unset;
      opacity: 1;
      transition: 0.5s all ease;
    }
    &.reveal.active {
      transform: unset;
      opacity: 1;
    }
  }

  .App > div > .row {
    height: auto;
  }

  .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0%;
    background-color: white;
    width: 100%;
    border: 2px solid;
    justify-content: center;
    z-index: 9;
  }

  .navbar-toggler-icon-close {
    display: block;
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: #00ffe8;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
    align-self: end;
    margin-right: 20px;
    margin-top: 20px;

    &:before,
    &:after {
      position: absolute;
      top: 15%;
      left: calc(50% - 0.0625em);
      width: 0.125em;
      height: 70%;
      border-radius: 0.125em;
      transform: rotate(45deg);
      background: currentcolor;
      content: "";
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  #basic-navbar-nav a.nav-link {
    background-image: unset;
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
  }

  .navbar-toggler {
    z-index: 10;
  }

  .bigo-container .navbar {
    margin-left: unset;
    margin-right: unset;
    padding-left: 50px;
    padding-right: 50px;

    & > .d-flex {
      justify-content: space-between;
    }
  }

  .show-mobile {
    display: block;
  }

  .global-presence {
    .exploremore {
      float: unset;
      margin-bottom: 50px;
    }

    .watchlive {
      margin-left: unset;
    }

    .row {
      flex-flow: column;
    }

    .mobile {
      .stream {
        object-fit: cover;
        object-position: top;
        height: 350px;
      }
    }

    .react-player__preview {
      margin-left: unset;
    }
  }

  .about-us {
    justify-content: center;

    > div.row {
      height: auto;
    }

    .header {
      margin-top: 0px;
    }
  }

  .global-presence {
    .header {
      margin-top: 0px;
    }

    .exploremore {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .watchlive {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .join-us {
    margin-left: -104px;
    margin-right: -104px;
    justify-content: center;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    > div.row {
      height: 100%;
      padding-top: 80px;
    }

    .content {
      display: none;
    }

    .join-us-columns-container {
      display: block;
      overflow-x: hidden;
      overflow-y: scroll;
      scroll-snap-type: unset;

      .join-us-columns {
        scroll-snap-align: center;
      }
    }

    .plr-20p {
      padding-left: 20%;
      padding-right: 15%;
    }
  }

  .business-opportunity {
    padding-bottom: unset;

    .content {
      display: none;
    }

    > div.row {
      height: 100%;
    }

    .business-image-container .b1,
    .business-image-container .b4,
    .business-image-container .b2,
    .business-image-container .b3 {
      height: 50%;
      margin-top: 80px;
    }
  }

  .contact-us {
    margin-left: 0px;
    margin-right: -20px;
    width: auto;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .navbar {
    .navbar-toggler {
      position: absolute;
      right: 20px;
      border: unset;
      top: 15px;
    }
  }

  .fire-container {
    mask-size: 80%;
    height: 1000px;

    .img-content {
      height: 1000px;
      background-size: 70%;
      background-position-y: 87%;
      position: relative;

      .context {
        margin-top: unset;
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .control-dots{
      bottom: 15% !important;
    }
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 80%;
    background-position-x: 66px;
    background-position-y: 65%;
    height: 1000px;
  }

  .fire-content {
    display: none;
  }

  .pad-9m {
    padding-top: unset;
  }
}

@media (max-width: 767px) {
  .App > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &.join-us {
      height: unset;
    }
    &.business-opportunity {
      height: unset;
    }
    &.contact-us {
      height: unset;
    }
    &.reveal {
      position: relative;
      transform: unset;
      opacity: 1;
      transition: 0.5s all ease;
    }
    &.reveal.active {
      transform: unset;
      opacity: 1;
    }
  }

  .App > div > .row {
    height: auto;
  }

  .center-mobile {
    text-align: center;
  }

  .App {
    padding-left: 25px;
    padding-right: 25px;
    overflow-x: hidden;
  }

  .about-us {
    .header {
      margin-top: unset;
      font-size: 56px;
    }
  }

  .join-us {
    height: unset;

    > div.row {
      height: 100%;
      padding-top: 80px;
      padding-bottom: 50px;
    }

    .join-us-columns-container {
      display: block;
      overflow-x: hidden;
      overflow-y: unset;
      scroll-snap-type: unset;

      .join-us-columns {
        scroll-snap-align: center;
      }
    }

    .plr-20p {
      padding-left: 20%;
      padding-right: 15%;
    }
  }

  .business-opportunity {
    height: unset;
    padding-bottom: 0px;

    .ta-center-mobile {
      text-align: center;
    }

    .header {
      font-size: 40px;
    }

    .business-image-container .b1,
    .business-image-container .b4,
    .business-image-container .b2,
    .business-image-container .b3 {
      height: 50%;
      margin-top: 80px;
    }
  }

  .global-presence {
    margin-bottom: 100px;

    .row {
      flex-flow: column;
    }
    .order-2-mobile {
      order: 2;
    }

    .order-1-mobile {
      order: 1;
    }

    .mobile {
      .stream {
        margin-left: unset;
        height: 330px;
        object-fit: cover;
        object-position: top;
      }
    }
    .header {
      font-size: 56px;
      text-align: right;
      margin-top: 0;
    }
    .content {
      font-size: 18px;
      font-family: "SFUIText";
      text-align: right;
      margin-top: 1em;
    }

    .exploremore {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .global-event {
    .center-mobile {
      text-align: center;
    }
  }

  .contact-us {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 0px;
    margin-right: 0px;

    > div.row {
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .contact-us-columns-container {
      display: block;
      overflow-x: hidden;
      overflow-y: unset;
      scroll-snap-type: unset;
    }

    .footer-link {
      li {
        margin-left: auto;
        margin-right: auto;

        a {
          font-size: 12px;
        }
      }
    }
  }

  .fire-container {
    mask-size: 80%;
    height: 1000px;

    .emps-bg {
      background-position-y: 0em !important;
      background-position-x: -8em !important;
    }

    .emie-bg {
      background-position-x: -10em;
    }

    .img-content {
      height: 1000px;
      width: 100%;
      background-size: 65%;
      background-position-y: 90%;
      position: relative;

      .context {
        margin-top: unset;
        position: absolute;
        bottom: 17%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    #ecstacy69 {
      background-position-y: 0;
      background-position-x: 45%;
    }

    .scroll-container {
      z-index: 10;
      position: fixed;
      bottom: 60px;
      left: 85%;
      transform: translateX(-40%);
      align-items: center;
    }

    .control-dots{
      bottom: 10% !important;
    }
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 80%;
    background-position-x: 65px;
    background-position-y: 65%;
    height: 1000px;
  }
}

// mobile
@media (max-width: 480px) {
  h1 {
    font-size: 30px !important;
  }

  .App {
    scroll-snap-type: unset;
    overflow-x: hidden;
    padding-top: 80px;
  }

  .App > div {
    display: flex;
    align-items: center;
    height: unset;
    padding-bottom: 100px;

    &.reveal {
      position: relative;
      transform: unset;
      opacity: 1;
      transition: 0.5s all ease;
    }
    &.reveal.active {
      transform: unset;
      opacity: 1;
    }
  }

  .App > div > .row {
    overflow-x: hidden;
    // height: auto;
  }

  .btn-primary {
    font-size: 20px !important;
  }

  .bigo-container .navbar {
    margin-left: unset;
    margin-right: unset;
    padding-left: 20px;
    padding-right: 20px;

    & > .d-flex {
      justify-content: space-between;
    }
  }

  .join-us {
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: unset;

    .header,
    .content {
      text-align: center;
    }

    .plr-20p {
      padding-left: 10%;
      padding-right: 5%;
    }

    input,
    select {
      font-size: 16px;
    }

    #agree{
      font-size: 20px;
    }

    > div.row {
      // height: 100%;
      padding-top: unset;
      padding-bottom: 0px;
    }

    .join-us-columns-container {
      display: block;
      overflow-x: hidden;
      overflow-y: unset;
      scroll-snap-type: unset;

      .join-us-columns {
        scroll-snap-align: center;
      }
    }
  }

  .business-opportunity {
    margin-bottom: unset;
    flex-direction: unset;
    padding-bottom: unset;

    .header,
    .content {
      text-align: center;
    }

    .plr-20p {
      padding-left: 10%;
      padding-right: 5%;
    }

    .business-image-container .b1,
    .business-image-container .b4,
    .business-image-container .b2,
    .business-image-container .b3 {
      height: 50%;
      margin-top: 80px;
      width: 80%;
    }
  }

  .global-presence {
    margin-bottom: unset;

    .header,
    .content {
      text-align: center;
    }

    .mobile {
      .stream {
        height: 13em !important;
        width: 100% !important;
        video {
          height: unset !important;
          margin-left: unset !important;
          width: 100% !important;
        }
      }
    }

    .exploremore {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .global-event {
    padding-bottom: 50px;

    .header {
      text-align: center;
    }
  }
  .react-player__preview {
    margin-left: unset !important;
  }
  .contact-us {
    margin-left: 0px;
    margin-right: 0px;

    .contact-us-columns-container {
      display: block;
      overflow-x: hidden;
      overflow-y: scroll;
      scroll-snap-type: unset;

      .contact-us-columns {
        scroll-snap-align: center;
      }
    }

    > div.row {
      // height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .contact-details,
    .footer-link {
      flex-direction: column;
    }

    .contact-details div {
      width: 100%;
    }

    .social {
      padding-left: unset;
    }

    .footer-link {
      flex-direction: row;

      li {
        &::after {
          content: unset;
        }

        &:last-child::after {
          content: unset;
        }
      }
    }

    .ta-left-mobile {
      text-align: left;
    }
  }

  .fire-container {
    mask-size: 90%;
    height: 500px;
    .control-dots {
      bottom: 40px !important;
      .dot {
        width: 16px !important;
        height: 16px !important;
        border-radius: 50% !important;
        // background-color: #9C40FF !important;
        background-color: transparent !important;
        opacity: 1 !important;
        box-shadow: none !important;
      }
      .dot::before {
        content: "";
        display: flex;
        width: 8px;
        height: 8px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #9c40ff;
      }
      .dot.selected {
        opacity: 1 !important;
        background-color: transparent !important;
        border: 5px solid #9c40ff !important;
        border-radius: 50% !important;
        width: 16px !important;
        height: 16px !important;
      }
      .dot.selected::before {
        background-color: transparent !important;
      }
    }
    .img-content {
      height: 500px;
      background-size: 90%;
      background-position-y: 95%;
      position: relative;

      .context {
        margin-top: unset;
        position: absolute;
        bottom: 75px;
        left: 50%;
        transform: translateX(-50%);
        p {
          display: none;
        }

        h3 {
          font-size: 25px;
        }
      }
    }

    #ecstacy69 {
      background-position-y: 0 !important;
      background-position-x: 45% !important;
    }

    .scroll-container {
      position: absolute;
      bottom: 25px;
      left: 70%;
      transform: translateX(-30%);
      z-index: 10;
    }
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 90%;
    background-position-x: 70%;
    background-position-y: 50%;
    height: 500px;
  }

  .business-image-container {
    margin-left: -4%;
    margin-bottom: 2em;
    .business-img {
      width: 100%;
    }

    .b1:hover,
    .b2:hover,
    .b3:hover,
    .b4:hover {
      left: 4%;
      width: 100%;
    }
  }
}

@media (max-width: 375px){
  .fire-container {
    .img-content {
      background-position-y: 75%;
      .context {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}