@font-face {
    font-family: 'BIGOLIVESANS';
    src: url('/public/fonts/BIGOLIVESANS-Regular.ttf')  format('truetype')
}

@font-face {
    font-family: 'SFUIText';
    src: url('/public/fonts/SFUIText-Regular.ttf')  format('truetype')
}
html{
    max-width: 1920px;
    margin:auto;
    overflow-x: hidden;
}
.about-us-nav
{
    // background-image: url('/public/assets/ring.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 13px center;
}
.nav-link{
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}
#basic-navbar-nav
{
    a.nav-link
    {
        // font-weight: bold !important;
        font-family: 'BIGOLIVESANS';
        color:#000 !important;
        margin-left: 75px;
    }
}
.App{
    background-image: url('/public/assets/pic_background.png');
    min-height: 100vh;
    padding-left:104px;
    padding-right: 104px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.f-sfui{
    font-family: 'SFUIText';
}

h1, h2, h4, h4, h5, h6, .f-bigo{
    font-family: 'BIGOLIVESANS';
}

.btn-primary,  .btn-primary:focus{
    background-color: #00FFE8 !important;
    border-color: #00FFE8 !important;
    color:#000 !important;

    &:hover{
        img{
            filter: invert(1);
        }
    }
    
}
.btn-primary:hover, .btn-primary:active{
    color:#00FFE8 !important;
    background-color: #000 !important;
    border-color: #000 !important;
}

option:hover {
    background-color: yellow !important;
}

select.decorated option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset !important;
}

.btn{
    font-size: 24px !important;
    padding-right: 2em !important;
    padding-left: 2em !important;
    border-radius: 1em !important;
}

.bigo-container
{
    max-width: 1920px;
    .navbar
    {
        padding-right: 104px;
        padding-left: 104px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
    }
}

.d-flex
{
    display: flex;
}
.w-100
{
    width: 100%;
}

.about-us
{
    .header
    {
        margin-top: 2em;
        font-size: 80px;
    }
    .about-content
    {
        max-width: 700px;
        margin: auto;
        margin-top: 2em;
        margin-bottom: 2em;
        font-size: 18px;
    }
    .dino
    {
        width: 100%;
        max-width: 479px;
        max-height: 728px;
        object-fit: contain;
    }
    .f-bigo
    {
        font-size: 24px;
    }
}

.global-presence
{
    .mobile
    {
        .stream
        {
            width: 100% !important;
            // margin-left: -19em;
            margin-bottom: 3em;
            video{
                height: 20em !important;
                margin-left: -20% !important;
                width: 75% !important;
            }
        }
    }
    .header
    {
        font-size: 56px;
        text-align: right;
        margin-top: 1em;
    }
    .content
    {
        font-size: 20px;
        font-family: 'SFUIText';
        text-align: right;
        margin-top: 1em;
    }
}

.global-event
{
    padding-bottom: 197px;
    .header
    {
        text-align: left;
        font-size: 56px;
    }
    .btn-primary{
        font-size: 24px;
    }
    
    
}
.text-right
{
    text-align: right;
}
.carousel-bigo
{
    margin-right: 34px;
    padding-top: 36px;
    .bg-blue
    {
        width: 100%;
        max-width: 337px;
        height: 188px;
        background-color: #00FFE8;
        border-radius: 10px;
        margin-top: -188px;
    }
    img{
        width: 100%;
        max-width: 337px;
        height: 188px;
        object-fit: cover;
        border-radius: 10px;
        border:2px solid #fff;
        position: relative;
        transition: transform 0.3s ease;
        transform: translateX(0px) translateY(0px);
    }
    img:hover{
        cursor: pointer;
        transform: translateX(8px) translateY(-8px);
    }
    .title
    {
        margin-top:32px;
        text-align: left;
        font-size: 24px;
        font-family: 'SFUIText';
    }
}

.contact-us
{
    width: 100%;
    background: #000000;
    min-height: 628px;
    padding-right: 104px;
    padding-left: 104px;
    color:white;
    a{
        color:white;
    }
    .header
    {
        
        font-family: 'BIGOLIVESANS';
        font-size: 56px;
        font-weight: 400;
        margin-top:120px;
        margin-bottom: 49px;
    }
    .business
    {
        width: 100%;
        // list-style: url('/public/assets/icon/ic_email.ico');
        padding-left: 0px !important;
        list-style: none;
        font-weight: 400;
        li{
            margin-bottom: 49px;
            display: flex;
        }
        .icon
        {
            width: 32px;
            height: 32px;
            object-fit: contain;
            display: inline-block;
            margin-right: 15px;;
        }
        .content
        {
            display: inline-block;
        }
        .business-name
        {
            font-size: 24px;
        }
        .business-contact
        {
            font-size: 16px;
            text-decoration: none;
            margin-top:16px;
        }
        .business-contact:hover{
            color:white;
            text-decoration: none;
        }
    }

    .social
    {
        list-style: none;
        padding-left: none !important;
        font-weight: 400;
        li{
            display: flex;
        }
        li{
            margin-bottom: 27px;
            display: flex;
        }
        .icon
        {
            width: 32px;
            height: 32px;
            object-fit: contain;
            display: inline-block;
            margin-right: 15px;;
        }
        .content
        {
            display: inline-block;
            font-size: 24px;
            a{
                text-decoration: none;
            }
            a:hover{
                text-decoration: none;
                color: white;
                cursor: pointer;
            }
        }
    }

    .footer-link{
        list-style: none;
        display: inline-flex;
        margin-top:30px;
        li{
            margin-right: 25px;
            a{
                font-size: 18px;
                color: #BFBFBF;
                font-weight: 400;
            }
        }
        li::after{
            content: '|';
            margin-left: 25px;
        }
        li:last-child:after{
            content: '';
        }
    }
}
.widget-container
{
    margin: auto;
    display: inline-block;
    margin-top: 86px;
    min-height: 356px;
    .qr-container
    {
        .app-container
        {
            width: 190px;
            height: 356px;
            background-color: white;
            border-radius: 5px;
            
            text-align: center;
            padding: 16px;
            .qr-header
            {
                font-size: 14px;
                color:#000 !important;
                font-family: 'SFUIText';
                margin-bottom: 6px;
                display: block;
            }
            .qr-code{
                margin-bottom: 16px;
                width: 120px;
            }
            .app-store{
                // text-align: center;
                .app-link{
                    margin-bottom: 16px;
                    display: block;
                }
            }
        }
        .arrow-right
        {
            position: absolute;
            margin-left: 95px;
            margin-top: -168px;
        }
        
    }
    .social-container
    {
        width: 190px;
        height: 128px;
        background-color: white;
        border-radius: 5px;
        margin-top: 295px;
        text-align: center;
        padding: 16px;
        .social-header
        {
            color: #000;
            font-family: 'SFUIText';
            font-size: 14px;
        }
        .social-icons
        {
            list-style: none;
            padding-left: 0px;
            li{
                float:left;
                margin-right: 8px;
                margin-top: 8px;
            }
        }
        .arrow-right
        {
            position: absolute;
            margin-left: 17px;
            margin-top: -61px;
        }
    }
}


.circle-icons
{
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin-left: 16px;
    vertical-align: bottom;
    li{
        margin-top: 16px;
    }
    .circle{
        width: 48px;
        height: 48px;
        background-color: white;
        border-radius: 50%;
    }
    .active{
        // background-color: #ff3264 !important;
        background-color: transparent !important;
    }
}
.mb-120
{
    margin-bottom: 120px;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid white;
    display: inline-block;
    vertical-align: 110px;
}
 
.require-error
{
    font-size: 14px;
    color: #FF3264;
    font-weight: 400;
    font-family: 'SFUIText';
    padding-left:0px !important;
    padding-right:0px !important;
    margin-top:8px !important;
}

.require-input
{
    border-color:#FF3264 !important;
}
.join-us, .business-opportunity
{
    .form-control, .form-select
    {
        padding-left:0px !important;
        padding-right:0px !important;
    }
}

.d-none{
    display: none;
}
.fire-line
{
    width: 100%;
    background-image: url('/public/assets/pic_fire_line.svg');
    height: 1000px;
    position: absolute;
    background-repeat: no-repeat;
    /* z-index: 1; */
    background-position-x: 48px;

}
.fire-container
{
    width: 100%;
    height: 1000px;
    background-color: #fec833;
    mask-image: url('/public/assets/pic_fire_yellow.svg');
    -webkit-mask-image: url('/public/assets/pic_fire_yellow.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    overflow: auto;
    .img-content
    {
        height: 1000px;
        text-align: center;
        background-size: 150%;
        background-repeat: no-repeat;
        background-position-x: center;
        .context
        {
            color: white;
            text-align: center;
            font-family: 'BIGOLIVESANS';
            display: inline-block;
            width: 100%;
            margin-top: 75%;
            h3{
                font-size: 32px;
            }
            span{
                font-size: 16px;
            }
        }
    }
    .emie-bg
    {
        background-image: url('/public/assets/artists/emie.jpg');
        background-position-y: 10em;
    }
    .emps-bg
    {
        background-image: url('/public/assets/artists/emps_albano.png');
        background-position-y: 2em !important;
        background-position-x: -8em !important;
    }
    .control-dots
    {
        bottom:5em !important;
        .dot{
            width: 32px !important;
            height: 32px !important;
            border-radius: 50% !important;
            // background-color: #9C40FF !important;
            background-color: transparent !important;
            opacity: 1 !important;
            box-shadow: none !important;
        }
        .dot::before{
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-color: #9C40FF;
        }
        .dot.selected
        {
            opacity: 1 !important;
            background-color: transparent !important;
            border: 10px solid #9C40FF !important;
            border-radius: 50% !important;
            width: 32px !important;
            height: 32px !important;
        }
        .dot.selected::before{
            background-color: transparent !important;
        }
    }
    .scroll-container
    {
        position: fixed;
        position: fixed;
        bottom: 10em;
        left: 65%;
        transform: translateX(-50%);
        
        .scroller
        {
            list-style: none;
            padding-left: 0px;

            li{
                margin-top: 16px;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                float: left;
                
                .circle{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #9C40FF;
                    margin:auto;
                }
                .active{
                    background-color: transparent !important;
                    border: 10px solid #9C40FF;
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                }
                .active:hover{
                    background-color: transparent !important;
                    border: 10px solid #FF3264;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
                .circle:hover
                {
                    background-color: #FF3264 !important;
                    cursor: pointer;
                }
            }
            
        }
    }
    
}

.react-player__preview
{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    margin-left: -10%;
}
.react-player__shadow
{
    background: white !important;
}
.react-player__play-icon
{
    border-color: transparent transparent transparent black !important;
    
}

.business-image-container
{
    height: 45em;
    display: flex;
    position: relative;
    margin-left: 15%;
    .mobile-img{
        height: 100%;
    }
    .business-img
    {
        -webkit-transform: translateX(0) perspective(900px) rotateY(30deg);
        -moz-transform: translateX(0) perspective(900px) rotateY(30deg);
        -ms-transform: translateX(0) perspective(900px) rotateY(30deg);
        -o-transform: translateX(0) perspective(900px) rotateY(30deg);
        transform: translateX(0) perspective(900px) rotateY(30deg);
        border: 5px solid #fff;
        border-radius: 10px;
        height: 70%;
        position: absolute;
        top: 8em;
        cursor: pointer;
    }
    .mobile-bg
    {
        transform: perspective(600px) rotateY(17deg);
        border: 5px solid #fff;
        border-radius: 10px;
        height: 506px;
        position: absolute;
        top: 8em;
        width: 205px;
        left: 43px;
    }
    .b1
    {
        left: 29%;
        height: 74%;
        top: 6em;
        z-index: 3;
    }
    .b2{
        left: 21%;
        height: 72%;
        top: 7em;
        z-index: 2;
    }
    .b3{
        left: 15%;
        height: 70%;
        z-index: 1;
    }
    .b4{
        left: 10%;
        height: 67%;
        z-index: 1;
        top: 8.5em;
    }
    .b1:hover, .b2:hover, .b3:hover, .b4:hover{
        height: 74%;
        z-index: 4;
        transform: unset;
        top: 6em;
        transition: transform 1s;
    }

}

.pop-bg
{
    background-color: rgba(0,0,0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.swal2-styled
{
    padding: 0.375rem 2em !important;
}

.swal2-popup::before{
    content: '';
    width: 6em;
    height: 6em;
    background-image: url(/src/assets/pic_dino_head.png);
    margin-top: -5.96em;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.pad-140
{
    // padding-top: 140px;
}
.pad-9m
{
    padding-top: 9em;
}

@media screen and (max-width: 1024px) and (min-width : 1020px) {
    .App{
        padding-left: 52px !important;
        padding-right: 52px !important;
    }
    .react-player__preview{
        margin-left: 0% !important;
    }
    .global-presence{
        .watchlive{
            margin-left: 0em !important;
        }
        .mobile {
            .stream{
                margin-top: 6.5em;
                video
                {
                    margin-left: 0 !important;
                }
            }
        }
        
    } 
    .fire-line-container{
        margin-top:-20em !important;
        
        
    }
    .fire-container
    {
        .img-content{
            .context{
                margin-right: 45% !important;
                margin-top: 67% !important;
                margin-left: -28%;
            }
        }
    }
    .emie-bg
    {
        background-position-y: 23em !important;
        background-position-x: -10em !important;
        background-size: 80% !important;
    }
    #ecstacy69
    {
        background-position-y: 18em !important;
        background-position-x: -7em !important;
        background-size: 80%;
    }
    
    .join-us
    {
        height: auto !important;
        .fire-content{
            bottom: 213px !important;
            font-size: 12px !important;
        }
    }
    .business-image-container{
        margin-left: -4% !important;
        .business-img{
            width: 80% !important;
        }
        
    }
    .business-opportunity
    {
        .plr-20p
        {
            padding-right: 4% !important;
            padding-left: 4% !important;
        }
        .right.content{
            width: 100% !important;
            margin-top: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .left.content
        {
            margin-top:-26px !important;
        }
    }
}

@media screen and (max-width: 390px) {
    #emieloveee
    {
        background-position-x: -14em !important;
    }
}