@font-face {
  font-family: "BIGOLIVESANS";
  src: url("/public/fonts/BIGOLIVESANS-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SFUIText";
  src: url("/public/fonts/SFUIText-Regular.ttf") format("truetype");
}
html {
  max-width: 1920px;
  margin: auto;
  overflow-x: hidden;
}

.about-us-nav {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 13px center;
}

.nav-link {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

#basic-navbar-nav a.nav-link {
  font-family: "BIGOLIVESANS";
  color: #000 !important;
  margin-left: 75px;
}

.App {
  background-image: url("/public/assets/pic_background.png");
  min-height: 100vh;
  padding-left: 104px;
  padding-right: 104px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.f-sfui {
  font-family: "SFUIText";
}

h1, h2, h4, h4, h5, h6, .f-bigo {
  font-family: "BIGOLIVESANS";
}

.btn-primary, .btn-primary:focus {
  background-color: #00FFE8 !important;
  border-color: #00FFE8 !important;
  color: #000 !important;
}
.btn-primary:hover img, .btn-primary:focus:hover img {
  filter: invert(1);
}

.btn-primary:hover, .btn-primary:active {
  color: #00FFE8 !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

option:hover {
  background-color: yellow !important;
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882A8 inset !important;
}

.btn {
  font-size: 24px !important;
  padding-right: 2em !important;
  padding-left: 2em !important;
  border-radius: 1em !important;
}

.bigo-container {
  max-width: 1920px;
}
.bigo-container .navbar {
  padding-right: 104px;
  padding-left: 104px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.about-us .header {
  margin-top: 2em;
  font-size: 80px;
}
.about-us .about-content {
  max-width: 700px;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 18px;
}
.about-us .dino {
  width: 100%;
  max-width: 479px;
  max-height: 728px;
  object-fit: contain;
}
.about-us .f-bigo {
  font-size: 24px;
}

.global-presence .mobile .stream {
  width: 100% !important;
  margin-bottom: 3em;
}
.global-presence .mobile .stream video {
  height: 20em !important;
  margin-left: -20% !important;
  width: 75% !important;
}
.global-presence .header {
  font-size: 56px;
  text-align: right;
  margin-top: 1em;
}
.global-presence .content {
  font-size: 20px;
  font-family: "SFUIText";
  text-align: right;
  margin-top: 1em;
}

.global-event {
  padding-bottom: 197px;
}
.global-event .header {
  text-align: left;
  font-size: 56px;
}
.global-event .btn-primary {
  font-size: 24px;
}

.text-right {
  text-align: right;
}

.carousel-bigo {
  margin-right: 34px;
  padding-top: 36px;
}
.carousel-bigo .bg-blue {
  width: 100%;
  max-width: 337px;
  height: 188px;
  background-color: #00FFE8;
  border-radius: 10px;
  margin-top: -188px;
}
.carousel-bigo img {
  width: 100%;
  max-width: 337px;
  height: 188px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #fff;
  position: relative;
  transition: transform 0.3s ease;
  transform: translateX(0px) translateY(0px);
}
.carousel-bigo img:hover {
  cursor: pointer;
  transform: translateX(8px) translateY(-8px);
}
.carousel-bigo .title {
  margin-top: 32px;
  text-align: left;
  font-size: 24px;
  font-family: "SFUIText";
}

.contact-us {
  width: 100%;
  background: #000000;
  min-height: 628px;
  padding-right: 104px;
  padding-left: 104px;
  color: white;
}
.contact-us a {
  color: white;
}
.contact-us .header {
  font-family: "BIGOLIVESANS";
  font-size: 56px;
  font-weight: 400;
  margin-top: 120px;
  margin-bottom: 49px;
}
.contact-us .business {
  width: 100%;
  padding-left: 0px !important;
  list-style: none;
  font-weight: 400;
}
.contact-us .business li {
  margin-bottom: 49px;
  display: flex;
}
.contact-us .business .icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: inline-block;
  margin-right: 15px;
}
.contact-us .business .content {
  display: inline-block;
}
.contact-us .business .business-name {
  font-size: 24px;
}
.contact-us .business .business-contact {
  font-size: 16px;
  text-decoration: none;
  margin-top: 16px;
}
.contact-us .business .business-contact:hover {
  color: white;
  text-decoration: none;
}
.contact-us .social {
  list-style: none;
  padding-left: none !important;
  font-weight: 400;
}
.contact-us .social li {
  display: flex;
}
.contact-us .social li {
  margin-bottom: 27px;
  display: flex;
}
.contact-us .social .icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: inline-block;
  margin-right: 15px;
}
.contact-us .social .content {
  display: inline-block;
  font-size: 24px;
}
.contact-us .social .content a {
  text-decoration: none;
}
.contact-us .social .content a:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.contact-us .footer-link {
  list-style: none;
  display: inline-flex;
  margin-top: 30px;
}
.contact-us .footer-link li {
  margin-right: 25px;
}
.contact-us .footer-link li a {
  font-size: 18px;
  color: #BFBFBF;
  font-weight: 400;
}
.contact-us .footer-link li::after {
  content: "|";
  margin-left: 25px;
}
.contact-us .footer-link li:last-child:after {
  content: "";
}

.widget-container {
  margin: auto;
  display: inline-block;
  margin-top: 86px;
  min-height: 356px;
}
.widget-container .qr-container .app-container {
  width: 190px;
  height: 356px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding: 16px;
}
.widget-container .qr-container .app-container .qr-header {
  font-size: 14px;
  color: #000 !important;
  font-family: "SFUIText";
  margin-bottom: 6px;
  display: block;
}
.widget-container .qr-container .app-container .qr-code {
  margin-bottom: 16px;
  width: 120px;
}
.widget-container .qr-container .app-container .app-store .app-link {
  margin-bottom: 16px;
  display: block;
}
.widget-container .qr-container .arrow-right {
  position: absolute;
  margin-left: 95px;
  margin-top: -168px;
}
.widget-container .social-container {
  width: 190px;
  height: 128px;
  background-color: white;
  border-radius: 5px;
  margin-top: 295px;
  text-align: center;
  padding: 16px;
}
.widget-container .social-container .social-header {
  color: #000;
  font-family: "SFUIText";
  font-size: 14px;
}
.widget-container .social-container .social-icons {
  list-style: none;
  padding-left: 0px;
}
.widget-container .social-container .social-icons li {
  float: left;
  margin-right: 8px;
  margin-top: 8px;
}
.widget-container .social-container .arrow-right {
  position: absolute;
  margin-left: 17px;
  margin-top: -61px;
}

.circle-icons {
  list-style: none;
  padding-left: 0;
  display: inline-block;
  margin-left: 16px;
  vertical-align: bottom;
}
.circle-icons li {
  margin-top: 16px;
}
.circle-icons .circle {
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 50%;
}
.circle-icons .active {
  background-color: transparent !important;
}

.mb-120 {
  margin-bottom: 120px;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  display: inline-block;
  vertical-align: 110px;
}

.require-error {
  font-size: 14px;
  color: #FF3264;
  font-weight: 400;
  font-family: "SFUIText";
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 8px !important;
}

.require-input {
  border-color: #FF3264 !important;
}

.join-us .form-control, .join-us .form-select, .business-opportunity .form-control, .business-opportunity .form-select {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.d-none {
  display: none;
}

.fire-line {
  width: 100%;
  background-image: url("/public/assets/pic_fire_line.svg");
  height: 1000px;
  position: absolute;
  background-repeat: no-repeat;
  /* z-index: 1; */
  background-position-x: 48px;
}

.fire-container {
  width: 100%;
  height: 1000px;
  background-color: #fec833;
  mask-image: url("/public/assets/pic_fire_yellow.svg");
  -webkit-mask-image: url("/public/assets/pic_fire_yellow.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  overflow: auto;
}
.fire-container .img-content {
  height: 1000px;
  text-align: center;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position-x: center;
}
.fire-container .img-content .context {
  color: white;
  text-align: center;
  font-family: "BIGOLIVESANS";
  display: inline-block;
  width: 100%;
  margin-top: 75%;
}
.fire-container .img-content .context h3 {
  font-size: 32px;
}
.fire-container .img-content .context span {
  font-size: 16px;
}
.fire-container .emie-bg {
  background-image: url("/public/assets/artists/emie.jpg");
  background-position-y: 10em;
}
.fire-container .emps-bg {
  background-image: url("/public/assets/artists/emps_albano.png");
  background-position-y: 2em !important;
  background-position-x: -8em !important;
}
.fire-container .control-dots {
  bottom: 5em !important;
}
.fire-container .control-dots .dot {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  opacity: 1 !important;
  box-shadow: none !important;
}
.fire-container .control-dots .dot::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #9C40FF;
}
.fire-container .control-dots .dot.selected {
  opacity: 1 !important;
  background-color: transparent !important;
  border: 10px solid #9C40FF !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
}
.fire-container .control-dots .dot.selected::before {
  background-color: transparent !important;
}
.fire-container .scroll-container {
  position: fixed;
  position: fixed;
  bottom: 10em;
  left: 65%;
  transform: translateX(-50%);
}
.fire-container .scroll-container .scroller {
  list-style: none;
  padding-left: 0px;
}
.fire-container .scroll-container .scroller li {
  margin-top: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  float: left;
}
.fire-container .scroll-container .scroller li .circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #9C40FF;
  margin: auto;
}
.fire-container .scroll-container .scroller li .active {
  background-color: transparent !important;
  border: 10px solid #9C40FF;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.fire-container .scroll-container .scroller li .active:hover {
  background-color: transparent !important;
  border: 10px solid #FF3264;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.fire-container .scroll-container .scroller li .circle:hover {
  background-color: #FF3264 !important;
  cursor: pointer;
}

.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin-left: -10%;
}

.react-player__shadow {
  background: white !important;
}

.react-player__play-icon {
  border-color: transparent transparent transparent black !important;
}

.business-image-container {
  height: 45em;
  display: flex;
  position: relative;
  margin-left: 15%;
}
.business-image-container .mobile-img {
  height: 100%;
}
.business-image-container .business-img {
  -webkit-transform: translateX(0) perspective(900px) rotateY(30deg);
  -moz-transform: translateX(0) perspective(900px) rotateY(30deg);
  -ms-transform: translateX(0) perspective(900px) rotateY(30deg);
  -o-transform: translateX(0) perspective(900px) rotateY(30deg);
  transform: translateX(0) perspective(900px) rotateY(30deg);
  border: 5px solid #fff;
  border-radius: 10px;
  height: 70%;
  position: absolute;
  top: 8em;
  cursor: pointer;
}
.business-image-container .mobile-bg {
  transform: perspective(600px) rotateY(17deg);
  border: 5px solid #fff;
  border-radius: 10px;
  height: 506px;
  position: absolute;
  top: 8em;
  width: 205px;
  left: 43px;
}
.business-image-container .b1 {
  left: 29%;
  height: 74%;
  top: 6em;
  z-index: 3;
}
.business-image-container .b2 {
  left: 21%;
  height: 72%;
  top: 7em;
  z-index: 2;
}
.business-image-container .b3 {
  left: 15%;
  height: 70%;
  z-index: 1;
}
.business-image-container .b4 {
  left: 10%;
  height: 67%;
  z-index: 1;
  top: 8.5em;
}
.business-image-container .b1:hover, .business-image-container .b2:hover, .business-image-container .b3:hover, .business-image-container .b4:hover {
  height: 74%;
  z-index: 4;
  transform: unset;
  top: 6em;
  transition: transform 1s;
}

.pop-bg {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.swal2-styled {
  padding: 0.375rem 2em !important;
}

.swal2-popup::before {
  content: "";
  width: 6em;
  height: 6em;
  background-image: url(/src/assets/pic_dino_head.png);
  margin-top: -5.96em;
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.pad-9m {
  padding-top: 9em;
}

@media screen and (max-width: 1024px) and (min-width: 1020px) {
  .App {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }

  .react-player__preview {
    margin-left: 0% !important;
  }

  .global-presence .watchlive {
    margin-left: 0em !important;
  }
  .global-presence .mobile .stream {
    margin-top: 6.5em;
  }
  .global-presence .mobile .stream video {
    margin-left: 0 !important;
  }

  .fire-line-container {
    margin-top: -20em !important;
  }

  .fire-container .img-content .context {
    margin-right: 45% !important;
    margin-top: 67% !important;
    margin-left: -28%;
  }

  .emie-bg {
    background-position-y: 23em !important;
    background-position-x: -10em !important;
    background-size: 80% !important;
  }

  #ecstacy69 {
    background-position-y: 18em !important;
    background-position-x: -7em !important;
    background-size: 80%;
  }

  .join-us {
    height: auto !important;
  }
  .join-us .fire-content {
    bottom: 213px !important;
    font-size: 12px !important;
  }

  .business-image-container {
    margin-left: -4% !important;
  }
  .business-image-container .business-img {
    width: 80% !important;
  }

  .business-opportunity .plr-20p {
    padding-right: 4% !important;
    padding-left: 4% !important;
  }
  .business-opportunity .right.content {
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .business-opportunity .left.content {
    margin-top: -26px !important;
  }
}
@media screen and (max-width: 390px) {
  #emieloveee {
    background-position-x: -14em !important;
  }
}
html {
  overflow-y: hidden;
}

.bigo-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}
.bigo-container::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.App {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  background-position: top;
}
.App::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.App > div {
  scroll-snap-align: start;
  height: auto;
  margin-bottom: 50px;
}
.App > div.reveal {
  position: relative;
  opacity: 0;
  transition: 0.5s all ease;
}
.App > div.reveal.active {
  opacity: 1;
}
.App > div > .row {
  height: 100%;
  align-items: center;
  height: 100vh;
}

input#agree,
input#BusinessagreeCheck {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
input#agree:hover,
input#BusinessagreeCheck:hover {
  background-color: black;
}
input#agree:checked,
input#BusinessagreeCheck:checked {
  background-color: black;
}

label#agree a,
label#Businessagree a {
  color: black;
}
label#agree a:hover,
label#Businessagree a:hover {
  color: #00ffe8;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus,
select:focus,
.form-select:focus {
  outline: none !important;
  border-color: #00ffe8;
  box-shadow: none !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.global-presence .watchlive {
  margin-left: -10em;
}
.global-presence .exploremore {
  margin-top: 50px;
  float: right;
}

.join-us {
  margin-bottom: 100px;
}
.join-us input,
.join-us select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  font-size: 20px;
  border-radius: 0px !important;
}
.join-us input:hover, .join-us input:focus {
  border-bottom: 2px solid #00ffe8;
  background-color: transparent;
  box-shadow: unset;
}
.join-us .select-items {
  background-color: white;
}
.join-us .select-items:hover {
  background-color: #00ffe8;
}
.join-us .join-us-columns {
  position: relative;
}
.join-us .plr-20p {
  padding-left: 20%;
  padding-right: 20%;
}
.join-us .ta-left {
  text-align: left;
}
.join-us .header {
  font-size: 56px;
  text-align: center;
}
.join-us .row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.join-us .apply {
  margin-top: 50px;
}
.join-us .content {
  font-size: 18px;
  font-weight: bold;
  font-family: "SFUIText";
  text-align: left;
  margin-top: 1em;
  width: 600px;
  margin-bottom: 40px;
  margin-top: 10px;
}
.join-us .fire-content {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "BIGOLIVESANS";
  color: white;
}
.join-us .socmed-links .ic {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}
.join-us .socmed-links a {
  margin-left: 5px;
}
.join-us .socmed-links button {
  height: 44px;
  width: 130px;
  font-size: 18px;
  color: white;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.join-us .socmed-links button:hover {
  background-color: black;
}
.join-us .socmed-links span {
  font-family: "BIGOLIVESANS";
  font-size: 24px;
  margin-right: 10px;
}
.join-us .socmed-links .telegram {
  background-color: #2ca5e0;
  border: 1px solid #2ca5e0;
}
.join-us .socmed-links .telegram:hover {
  background-color: #000;
  border: 1px solid #000;
}
.join-us .socmed-links .facebook {
  background-color: #1877f2;
  border: 1px solid #1877f2;
}
.join-us .socmed-links .facebook:hover {
  background-color: #000;
  border: 1px solid #000;
}
.join-us .socmed-links .viber {
  background-color: #6140bf;
  border: 1px solid #6140bf;
}
.join-us .socmed-links .viber:hover {
  background-color: #000;
  border: 1px solid #000;
}
.join-us .artist {
  width: 100%;
}

.business-opportunity {
  margin-bottom: 100px;
}
.business-opportunity input,
.business-opportunity select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid;
  font-size: 20px;
  border-radius: 0px !important;
}
.business-opportunity input:hover, .business-opportunity input:focus {
  border-bottom: 1px solid #00ffe8;
  background-color: transparent;
  box-shadow: unset;
}
.business-opportunity .select-items {
  background-color: white;
}
.business-opportunity .select-items:hover {
  background-color: #00ffe8;
}
.business-opportunity .header {
  font-size: 56px;
}
.business-opportunity .row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.business-opportunity .content {
  font-weight: bold;
}
.business-opportunity .right.content {
  font-size: 18px;
  font-family: "SFUIText";
  text-align: right;
  margin-top: 1em;
  margin-top: 10px;
  width: 500px;
  margin-left: -75px;
  margin-bottom: 65px;
}
.business-opportunity .left.content {
  font-size: 18px;
  font-family: "SFUIText";
  text-align: center;
  margin-top: 1em;
  margin-top: 10px;
}
.business-opportunity .ta-right {
  text-align: right;
}
.business-opportunity .plr-20p {
  padding-right: 20%;
  padding-left: 20%;
}
.business-opportunity .business-image {
  height: 100%;
}
.business-opportunity .apply {
  margin-top: 50px;
}
.business-opportunity label#agree,
.business-opportunity label#Businessagree {
  text-align: left;
}

.contact-us {
  scroll-snap-align: start;
}
.contact-us.active {
  margin-bottom: 0px;
}
.contact-us > .row {
  height: 100vh;
  align-items: center;
}
.contact-us .socmed-links .business-name {
  float: left;
}

.navbar-nav #close {
  display: none;
}

.show-mobile {
  display: none;
}

.fire-container {
  mask-size: 80%;
  height: 1000px;
  scroll-snap-type: x mandatory;
  -webkit-mask-position-y: 65%;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
}
.fire-container .img-content {
  height: 1000px;
  width: 100%;
  background-size: 80%;
  background-position-x: 60%;
  background-position-y: 81%;
  scroll-snap-align: center;
  display: inline-block;
}

.fire-line-container {
  position: relative;
}

.fire-line {
  background-size: 80%;
  background-position-x: 120px;
  background-position-y: 70%;
  height: 1000px;
}

.collapsed .navbar-toggler-icon {
  background-image: url("/public/assets/menu.png") !important;
}

.navbar-toggler-icon {
  background-image: url("/public/assets/close.png") !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-note {
  text-align: left;
  font-size: 10px;
}

.pad-9m {
  padding-top: unset;
}

#Prime_Cess {
  color: #9C40FF;
}

@media (max-width: 1600px) {
  .join-us .right.content {
    width: unset;
  }
  .join-us .fire-content {
    bottom: 17%;
    left: 52%;
  }

  .business-opportunity .business-image-container .b1,
.business-opportunity .business-image-container .b4,
.business-opportunity .business-image-container .b2,
.business-opportunity .business-image-container .b3 {
    height: 65%;
    margin-top: 0px;
    width: 65%;
  }

  .fire-container .img-content .context {
    width: auto !important;
    /* margin-right: 17% !important; */
    position: absolute;
    bottom: 23%;
    left: 50%;
    transform: translateX(-50%);
  }
  .fire-container .emie-bg {
    background-position-y: 13em;
    background-position-x: -18em;
  }
  .fire-container .emps-bg {
    background-position-y: 6em !important;
    background-position-x: -14em !important;
  }
  .fire-container .control-dots {
    bottom: 13% !important;
  }
  .fire-container .control-dots .dot {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }
  .fire-container .control-dots .dot::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #9c40ff;
  }
  .fire-container .control-dots .dot.selected {
    opacity: 1 !important;
    background-color: transparent !important;
    border: 10px solid #9c40ff !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
  }
  .fire-container .control-dots .dot.selected::before {
    background-color: transparent !important;
  }
}
@media (min-width: 1317px) and (max-width: 1512px) {
  .join-us .socmed-links span {
    font-size: 20px;
  }
  .join-us .socmed-links a {
    margin-left: 5px;
  }
  .join-us .socmed-links button {
    width: 130px;
  }
}
@media (min-width: 1024px) and (max-width: 1316px) {
  .join-us .socmed-links span {
    font-size: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .join-us .fire-container {
    height: 500px;
  }
  .join-us .fire-container .emps-bg {
    background-position-y: 12em !important;
    background-position-x: -20em !important;
  }
  .join-us .fire-container .emie-bg {
    background-position-x: -25em !important;
    background-position-y: 20em !important;
  }
  .join-us .fire-container .img-content {
    height: 500px;
    width: 100%;
    background-size: 75%;
    background-position-x: center;
    background-position-y: 95%;
  }
  .join-us .fire-container .img-content .context {
    width: auto !important;
    margin: 0 !important;
    bottom: 15%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .join-us .fire-container .img-content .context h3 {
    font-size: 20px;
  }
  .join-us .fire-content {
    bottom: 20% !important;
    left: 52%;
    font-size: 13px;
  }
  .join-us .fire-line {
    height: 500px;
  }
  .join-us .control-dots {
    bottom: 2% !important;
  }
}
@media (max-width: 1199px) {
  .show-mobile {
    display: block;
  }

  .navbar-nav .nav-link.active {
    background-image: unset;
  }

  #basic-navbar-nav a.nav-link {
    background-image: unset;
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
  }

  .navbar-toggler-icon-close {
    display: block;
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: #00ffe8;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
    align-self: end;
    margin-right: 20px;
    margin-top: 20px;
  }
  .navbar-toggler-icon-close:before, .navbar-toggler-icon-close:after {
    position: absolute;
    top: 15%;
    left: calc(50% - 0.0625em);
    width: 0.125em;
    height: 70%;
    border-radius: 0.125em;
    transform: rotate(45deg);
    background: currentcolor;
    content: "";
  }
  .navbar-toggler-icon-close:after {
    transform: rotate(-45deg);
  }

  .navbar-toggler {
    z-index: 10;
  }

  .navbar .navbar-toggler {
    position: absolute;
    right: 20px;
    border: unset;
    top: 15px;
  }

  .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0%;
    background-color: white;
    width: 100%;
    border: 2px solid;
    justify-content: center;
    z-index: 9;
  }

  .business-image-container .b1,
.business-image-container .b4,
.business-image-container .b2,
.business-image-container .b3 {
    height: 50%;
    margin-top: 80px;
  }
}
@media (max-width: 1080px) {
  .bigo-container .navbar {
    padding-left: 50px;
  }
}
@media (max-width: 1023px) {
  .bigo-container {
    scroll-snap-type: unset;
  }

  .App > div {
    display: flex;
    align-items: center;
  }
  .App > div.reveal {
    position: relative;
    transform: unset;
    opacity: 1;
    transition: 0.5s all ease;
  }
  .App > div.reveal.active {
    transform: unset;
    opacity: 1;
  }

  .about-us {
    justify-content: center;
  }
  .about-us .row {
    flex-direction: column;
  }
  .about-us .row div {
    width: 100%;
  }
  .about-us .dino {
    display: none;
  }

  .global-presence .row {
    flex-direction: column;
  }
  .global-presence .row div {
    width: 100%;
  }
  .global-presence .row .order-2-mobile {
    order: 2;
  }
  .global-presence .row .order-1-mobile {
    order: 1;
  }
  .global-presence .mobile .stream {
    margin-left: auto;
    margin-right: auto;
  }
  .global-presence .header {
    margin-top: 5rem;
  }

  .global-event {
    flex-direction: column;
    padding-bottom: 50px;
  }
  .global-event .jAMlvv {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .global-event .jAMlvv .iKLtWK {
    width: 190px;
  }
  .global-event > .row:nth-child(1) > :nth-child(1),
.global-event > .row:nth-child(1) :nth-child(2) {
    width: 100%;
  }

  .join-us {
    margin-bottom: 50px;
  }
  .join-us .row {
    flex-direction: column;
  }
  .join-us .row div {
    width: 100%;
  }
  .join-us .artist {
    display: none;
  }

  .business-opportunity {
    flex-direction: column;
    padding-bottom: 100px;
    margin-bottom: unset;
  }
  .business-opportunity .row {
    text-align: left;
  }
  .business-opportunity .row div {
    width: 100%;
  }
  .business-opportunity .business-image {
    display: none;
  }

  .ta-left-mobile {
    text-align: left;
  }

  #basic-navbar-nav a.nav-link {
    margin-left: 50px;
  }

  .fire-container {
    mask-size: 100%;
    height: 1000px;
  }
  .fire-container .img-content {
    height: 1000px;
    background-size: 90%;
  }
  .fire-container .img-content .context {
    margin-top: 95%;
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 100%;
    background-position-x: 0;
    background-position-y: 50%;
    height: 1000px;
  }

  .contact-us .footer-link li a {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .App > div {
    display: flex;
    align-items: center;
  }
  .App > div.join-us {
    height: unset;
  }
  .App > div.business-opportunity {
    height: unset;
  }
  .App > div.contact-us {
    height: unset;
  }
  .App > div.reveal {
    position: relative;
    transform: unset;
    opacity: 1;
    transition: 0.5s all ease;
  }
  .App > div.reveal.active {
    transform: unset;
    opacity: 1;
  }

  .App > div > .row {
    height: auto;
  }

  .navbar-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0%;
    background-color: white;
    width: 100%;
    border: 2px solid;
    justify-content: center;
    z-index: 9;
  }

  .navbar-toggler-icon-close {
    display: block;
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: #00ffe8;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
    align-self: end;
    margin-right: 20px;
    margin-top: 20px;
  }
  .navbar-toggler-icon-close:before, .navbar-toggler-icon-close:after {
    position: absolute;
    top: 15%;
    left: calc(50% - 0.0625em);
    width: 0.125em;
    height: 70%;
    border-radius: 0.125em;
    transform: rotate(45deg);
    background: currentcolor;
    content: "";
  }
  .navbar-toggler-icon-close:after {
    transform: rotate(-45deg);
  }

  #basic-navbar-nav a.nav-link {
    background-image: unset;
    margin-top: 20px;
    font-size: 20px;
    margin-right: auto;
  }

  .navbar-toggler {
    z-index: 10;
  }

  .bigo-container .navbar {
    margin-left: unset;
    margin-right: unset;
    padding-left: 50px;
    padding-right: 50px;
  }
  .bigo-container .navbar > .d-flex {
    justify-content: space-between;
  }

  .show-mobile {
    display: block;
  }

  .global-presence .exploremore {
    float: unset;
    margin-bottom: 50px;
  }
  .global-presence .watchlive {
    margin-left: unset;
  }
  .global-presence .row {
    flex-flow: column;
  }
  .global-presence .mobile .stream {
    object-fit: cover;
    object-position: top;
    height: 350px;
  }
  .global-presence .react-player__preview {
    margin-left: unset;
  }

  .about-us {
    justify-content: center;
  }
  .about-us > div.row {
    height: auto;
  }
  .about-us .header {
    margin-top: 0px;
  }

  .global-presence .header {
    margin-top: 0px;
  }
  .global-presence .exploremore {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .global-presence .watchlive {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .join-us {
    margin-left: -104px;
    margin-right: -104px;
    justify-content: center;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
  }
  .join-us::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }
  .join-us > div.row {
    height: 100%;
    padding-top: 80px;
  }
  .join-us .content {
    display: none;
  }
  .join-us .join-us-columns-container {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: unset;
  }
  .join-us .join-us-columns-container .join-us-columns {
    scroll-snap-align: center;
  }
  .join-us .plr-20p {
    padding-left: 20%;
    padding-right: 15%;
  }

  .business-opportunity {
    padding-bottom: unset;
  }
  .business-opportunity .content {
    display: none;
  }
  .business-opportunity > div.row {
    height: 100%;
  }
  .business-opportunity .business-image-container .b1,
.business-opportunity .business-image-container .b4,
.business-opportunity .business-image-container .b2,
.business-opportunity .business-image-container .b3 {
    height: 50%;
    margin-top: 80px;
  }

  .contact-us {
    margin-left: 0px;
    margin-right: -20px;
    width: auto;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
  }
  .contact-us::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

  .navbar .navbar-toggler {
    position: absolute;
    right: 20px;
    border: unset;
    top: 15px;
  }

  .fire-container {
    mask-size: 80%;
    height: 1000px;
  }
  .fire-container .img-content {
    height: 1000px;
    background-size: 70%;
    background-position-y: 87%;
    position: relative;
  }
  .fire-container .img-content .context {
    margin-top: unset;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .fire-container .control-dots {
    bottom: 15% !important;
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 80%;
    background-position-x: 66px;
    background-position-y: 65%;
    height: 1000px;
  }

  .fire-content {
    display: none;
  }

  .pad-9m {
    padding-top: unset;
  }
}
@media (max-width: 767px) {
  .App > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .App > div.join-us {
    height: unset;
  }
  .App > div.business-opportunity {
    height: unset;
  }
  .App > div.contact-us {
    height: unset;
  }
  .App > div.reveal {
    position: relative;
    transform: unset;
    opacity: 1;
    transition: 0.5s all ease;
  }
  .App > div.reveal.active {
    transform: unset;
    opacity: 1;
  }

  .App > div > .row {
    height: auto;
  }

  .center-mobile {
    text-align: center;
  }

  .App {
    padding-left: 25px;
    padding-right: 25px;
    overflow-x: hidden;
  }

  .about-us .header {
    margin-top: unset;
    font-size: 56px;
  }

  .join-us {
    height: unset;
  }
  .join-us > div.row {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .join-us .join-us-columns-container {
    display: block;
    overflow-x: hidden;
    overflow-y: unset;
    scroll-snap-type: unset;
  }
  .join-us .join-us-columns-container .join-us-columns {
    scroll-snap-align: center;
  }
  .join-us .plr-20p {
    padding-left: 20%;
    padding-right: 15%;
  }

  .business-opportunity {
    height: unset;
    padding-bottom: 0px;
  }
  .business-opportunity .ta-center-mobile {
    text-align: center;
  }
  .business-opportunity .header {
    font-size: 40px;
  }
  .business-opportunity .business-image-container .b1,
.business-opportunity .business-image-container .b4,
.business-opportunity .business-image-container .b2,
.business-opportunity .business-image-container .b3 {
    height: 50%;
    margin-top: 80px;
  }

  .global-presence {
    margin-bottom: 100px;
  }
  .global-presence .row {
    flex-flow: column;
  }
  .global-presence .order-2-mobile {
    order: 2;
  }
  .global-presence .order-1-mobile {
    order: 1;
  }
  .global-presence .mobile .stream {
    margin-left: unset;
    height: 330px;
    object-fit: cover;
    object-position: top;
  }
  .global-presence .header {
    font-size: 56px;
    text-align: right;
    margin-top: 0;
  }
  .global-presence .content {
    font-size: 18px;
    font-family: "SFUIText";
    text-align: right;
    margin-top: 1em;
  }
  .global-presence .exploremore {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .global-event .center-mobile {
    text-align: center;
  }

  .contact-us {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .contact-us > div.row {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-us .contact-us-columns-container {
    display: block;
    overflow-x: hidden;
    overflow-y: unset;
    scroll-snap-type: unset;
  }
  .contact-us .footer-link li {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-us .footer-link li a {
    font-size: 12px;
  }

  .fire-container {
    mask-size: 80%;
    height: 1000px;
  }
  .fire-container .emps-bg {
    background-position-y: 0em !important;
    background-position-x: -8em !important;
  }
  .fire-container .emie-bg {
    background-position-x: -10em;
  }
  .fire-container .img-content {
    height: 1000px;
    width: 100%;
    background-size: 65%;
    background-position-y: 90%;
    position: relative;
  }
  .fire-container .img-content .context {
    margin-top: unset;
    position: absolute;
    bottom: 17%;
    left: 50%;
    transform: translateX(-50%);
  }
  .fire-container #ecstacy69 {
    background-position-y: 0;
    background-position-x: 45%;
  }
  .fire-container .scroll-container {
    z-index: 10;
    position: fixed;
    bottom: 60px;
    left: 85%;
    transform: translateX(-40%);
    align-items: center;
  }
  .fire-container .control-dots {
    bottom: 10% !important;
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 80%;
    background-position-x: 65px;
    background-position-y: 65%;
    height: 1000px;
  }
}
@media (max-width: 480px) {
  h1 {
    font-size: 30px !important;
  }

  .App {
    scroll-snap-type: unset;
    overflow-x: hidden;
    padding-top: 80px;
  }

  .App > div {
    display: flex;
    align-items: center;
    height: unset;
    padding-bottom: 100px;
  }
  .App > div.reveal {
    position: relative;
    transform: unset;
    opacity: 1;
    transition: 0.5s all ease;
  }
  .App > div.reveal.active {
    transform: unset;
    opacity: 1;
  }

  .App > div > .row {
    overflow-x: hidden;
  }

  .btn-primary {
    font-size: 20px !important;
  }

  .bigo-container .navbar {
    margin-left: unset;
    margin-right: unset;
    padding-left: 20px;
    padding-right: 20px;
  }
  .bigo-container .navbar > .d-flex {
    justify-content: space-between;
  }

  .join-us {
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: unset;
  }
  .join-us .header,
.join-us .content {
    text-align: center;
  }
  .join-us .plr-20p {
    padding-left: 10%;
    padding-right: 5%;
  }
  .join-us input,
.join-us select {
    font-size: 16px;
  }
  .join-us #agree {
    font-size: 20px;
  }
  .join-us > div.row {
    padding-top: unset;
    padding-bottom: 0px;
  }
  .join-us .join-us-columns-container {
    display: block;
    overflow-x: hidden;
    overflow-y: unset;
    scroll-snap-type: unset;
  }
  .join-us .join-us-columns-container .join-us-columns {
    scroll-snap-align: center;
  }

  .business-opportunity {
    margin-bottom: unset;
    flex-direction: unset;
    padding-bottom: unset;
  }
  .business-opportunity .header,
.business-opportunity .content {
    text-align: center;
  }
  .business-opportunity .plr-20p {
    padding-left: 10%;
    padding-right: 5%;
  }
  .business-opportunity .business-image-container .b1,
.business-opportunity .business-image-container .b4,
.business-opportunity .business-image-container .b2,
.business-opportunity .business-image-container .b3 {
    height: 50%;
    margin-top: 80px;
    width: 80%;
  }

  .global-presence {
    margin-bottom: unset;
  }
  .global-presence .header,
.global-presence .content {
    text-align: center;
  }
  .global-presence .mobile .stream {
    height: 13em !important;
    width: 100% !important;
  }
  .global-presence .mobile .stream video {
    height: unset !important;
    margin-left: unset !important;
    width: 100% !important;
  }
  .global-presence .exploremore {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .global-event {
    padding-bottom: 50px;
  }
  .global-event .header {
    text-align: center;
  }

  .react-player__preview {
    margin-left: unset !important;
  }

  .contact-us {
    margin-left: 0px;
    margin-right: 0px;
  }
  .contact-us .contact-us-columns-container {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: unset;
  }
  .contact-us .contact-us-columns-container .contact-us-columns {
    scroll-snap-align: center;
  }
  .contact-us > div.row {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-us .contact-details,
.contact-us .footer-link {
    flex-direction: column;
  }
  .contact-us .contact-details div {
    width: 100%;
  }
  .contact-us .social {
    padding-left: unset;
  }
  .contact-us .footer-link {
    flex-direction: row;
  }
  .contact-us .footer-link li::after {
    content: unset;
  }
  .contact-us .footer-link li:last-child::after {
    content: unset;
  }
  .contact-us .ta-left-mobile {
    text-align: left;
  }

  .fire-container {
    mask-size: 90%;
    height: 500px;
  }
  .fire-container .control-dots {
    bottom: 40px !important;
  }
  .fire-container .control-dots .dot {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }
  .fire-container .control-dots .dot::before {
    content: "";
    display: flex;
    width: 8px;
    height: 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #9c40ff;
  }
  .fire-container .control-dots .dot.selected {
    opacity: 1 !important;
    background-color: transparent !important;
    border: 5px solid #9c40ff !important;
    border-radius: 50% !important;
    width: 16px !important;
    height: 16px !important;
  }
  .fire-container .control-dots .dot.selected::before {
    background-color: transparent !important;
  }
  .fire-container .img-content {
    height: 500px;
    background-size: 90%;
    background-position-y: 95%;
    position: relative;
  }
  .fire-container .img-content .context {
    margin-top: unset;
    position: absolute;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
  }
  .fire-container .img-content .context p {
    display: none;
  }
  .fire-container .img-content .context h3 {
    font-size: 25px;
  }
  .fire-container #ecstacy69 {
    background-position-y: 0 !important;
    background-position-x: 45% !important;
  }
  .fire-container .scroll-container {
    position: absolute;
    bottom: 25px;
    left: 70%;
    transform: translateX(-30%);
    z-index: 10;
  }

  .fire-line-container {
    position: relative;
  }

  .fire-line {
    background-size: 90%;
    background-position-x: 70%;
    background-position-y: 50%;
    height: 500px;
  }

  .business-image-container {
    margin-left: -4%;
    margin-bottom: 2em;
  }
  .business-image-container .business-img {
    width: 100%;
  }
  .business-image-container .b1:hover,
.business-image-container .b2:hover,
.business-image-container .b3:hover,
.business-image-container .b4:hover {
    left: 4%;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .fire-container .img-content {
    background-position-y: 75%;
  }
  .fire-container .img-content .context h3 {
    font-size: 20px;
  }
}

